import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'

import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'

function MyImage({ src, placeholderImage, errorImage, loadOnScroll, quality = 70, ...rest }) {
  const [url, setUrl] = useState(src)
  const { isLoaded } = useOnMouseAndScroll()

  useEffect(() => {
    setUrl(src)
  }, [src])

  function getUrl() {
    if (loadOnScroll) {
      return isLoaded ? url : placeholderImage
    } else {
      return url
    }
  }

  return (
    <Image
      src={getUrl()}
      quality={quality}
      onError={(e) => setUrl(errorImage || noImage)}
      {...rest}
    />
  )
}
MyImage.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  errorImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  placeholderImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  loadOnScroll: PropTypes.bool,
  quality: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}
export default React.memo(MyImage)
